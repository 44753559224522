@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  overscroll-behavior: contain; /*used to stop refreshing the page when scrolling upwards on mobile devices*/
}

html,
body {
  min-width: 100%;
  min-height: 100%;
  background: #121212;
}

.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  padding-left: 1.25rem /* 20px */;
  padding-right: 1.25rem /* 20px */;
  scroll-snap-align: start;
  place-content: center;
  background: #121212;
  padding-top: 7rem;

  height: calc(100vh - constant(safe-area-inset-top) - 4rem);
  height: calc(
    100vh - env(safe-area-inset-top) - 4rem
  ); /* Fallback for browsers not supporting constant() */
}

@media (min-width: 768px) {
  .page {
    padding-left: 1.75rem /* 28px */;
    padding-right: 1.75rem /* 28px */;
    place-content: space-evenly;
  }
}

.custom-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent; /* For Firefox */

  /* For Webkit (Chrome, Safari, Edge) */
  &::-webkit-scrollbar {
    width: 0.55rem;
    height: 0.55rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(167, 167, 167);
    border-radius: 8px;
  }
}

.icon:hover {
  color: #8abb10;
}

.icon {
  transition: 0.5s;
}

.asd {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.asd::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.navItem {
  padding: 1rem;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}

.navItem-active {
  padding: 1rem;
  color: #00df9a;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}

.navItem:hover {
  color: #00df9a;
}

.contactHolder {
  display: flex;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 1rem;
  line-height: 1.75rem;
  justify-content: flex-start;
  vertical-align: middle;
  --tw-bg-opacity: 1;
  background-color: rgb(63 63 70 / var(--tw-bg-opacity));
  border-radius: 0.5rem;
  padding: 0.5rem;
  transition: transform 0.3s ease;
  /*@media (min-width: 768px) {
    .md\:text-2xl {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }*/
}

@media only screen and (min-width: 768px) {
  .contactHolder {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

.contactHolder:hover {
  transform: scale(1.05);
}
